import * as React from "react";
import SEO from "../../components/seo";

import { StaticImage } from "gatsby-plugin-image";
import ContactUs from "../../components/ContactUs";
import MoreProjects from "../../components/MoreProjects";
import { useEffect, useState } from "react";

const SnackspacePage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <div className="">
        <SEO title="Snackspace - Spring" lang="en" />

        <div className="hidden md:block w-full -mt-24 left-0 -mb-0 right-0 h-[34rem] relative overflow-hidden">
          <div draggable="false" className="w-full h-full absolute bg-black" />
          <div className="h-full w-full flex justify-center items-center absolute -bottom-2 overflow-hidden ">
            <StaticImage
              draggable="false"
              className="bottom-0 absolute  object-contain max-w-3xl "
              src="../../images/projects/snackspace/header.png"
              alt="Snackspace Logo"
              placeholder="blurred"
            />
          </div>
        </div>

        <div className="max-w-7xl px-6 lg:px-8 mx-auto  text-black100 font-inter overflow-x-hidden">
          <div className="w-full font-inter mt-9 mb-20 md:my-20 text-left xl:mx-32">
            <h1 className="text-4xl md:text-4xl lg:text-5xl font-semibold ">
              Helping the UK discover the best of local{" "}
              <br className="hidden lg:block" /> & independent cafes,
              restaurants and <br className="hidden lg:block" /> bars.
            </h1>
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-52 md:mt-8 mt-6">
              <p className="md:w-1/2 max-w-[30rem] md:text-base">
                In a world where supporting local business is more important
                than ever, Snackspace helps independent eateries be discovered,
                and showcase the best of what they have to offer. Creating a
                cohesive experience for the user, with all information in one
                place; from reviews to best sellers, opening hours and offers.
              </p>
              <p className="md:text-base ">
                - UI Design <br />- UX Design <br />- Visual Identity <br />-
                Deck Design
              </p>
            </div>
          </div>
          {/* IMAGES */}
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                className="md:w-1/2"
                src="../../images/projects/snackspace/1o1.png"
                alt="Explore page with top rated restaurants"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/snackspace/1o2.png"
                alt="The Little Treehouse restaurants details"
                placeholder="blurred"
              />
            </div>
            <StaticImage
              draggable="false"
              className="w-full"
              src="../../images/projects/snackspace/2.png"
              alt="Snackspace components showcase"
              placeholder="blurred"
            />
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                className="md:w-1/2"
                src="../../images/projects/snackspace/3o1.png"
                alt="Restaurant reviews"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/snackspace/3o2.png"
                alt="Creating new product"
                placeholder="blurred"
              />
            </div>
            <StaticImage
              draggable="false"
              className="w-full"
              src="../../images/projects/snackspace/4.png"
              alt="Snackspace features showcased with title 'Organised, updated & easy to manage.'"
              placeholder="blurred"
            />
            {/* ---------------------------------------------------------------- */}
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/snackspace/5o1m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>

            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/snackspace/5o2m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/snackspace/5o3m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/snackspace/5o4m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/snackspace/5o1d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>

            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/snackspace/5o2d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
          </div>
          <MoreProjects excludeProject="snackspace" />
          <ContactUs />
        </div>
      </div>
    );
  }
};

export default SnackspacePage;
